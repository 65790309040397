/* ------------------------------------ *\
		tools.behavior
\* ------------------------------------ */

/* Mixin for transitions
	* to be compliant with
	* media-prefers-reduced-motion
	*
*/

@mixin transition {
	@content;
	@media (prefers-reduced-motion: reduce) {
		transition-duration: 0;
	}
}

@mixin custom-underline($status){	
	@if $status == activate {
		text-underline-offset: 0.5rem;
		text-decoration: underline;
		text-decoration-thickness: .15rem;
		transition: color .2s,text-underline-offset .2s,text-decoration-color .2s;
		text-decoration-color: transparent;
		&:hover {
			text-underline-offset: 1rem; 
			text-decoration: underline;
			text-decoration-color: currentColor;
			text-decoration-thickness: .15rem;
			transition: color .2s,text-underline-offset .2s,text-decoration-color .2s;
		}
	}
	
	@else if $status == disable {
		text-decoration: none;
		text-underline-offset: unset; 	
		text-decoration-color: unset;
		text-decoration-thickness: 0;
	}
}