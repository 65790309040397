@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.ServiceLinkList {
	padding-top: var(--spacing--10xl);
	padding-bottom: var(--spacing--6xl);
	@include coloredComponent;
}

.ServiceLinkList_heading {
	margin-bottom: var(--spacing--3xl);

	@include mq("md") {
		margin-bottom: var(--spacing--4xl);
	}
}

.ServiceLinkList_card {
	margin-bottom: var(--spacing--xl);
}

.ServiceLinkList_btnContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: var(--spacing--xl);
}

[data-theme='kogp'] {
	@include mq("md") {
		// Fix for showing the "right" order of the cards
		// We (kogp) is not using this component everywhere else other than on the frontpage
		.ServiceLinkList_gridCell:nth-of-type(1) { order: 1; }
		.ServiceLinkList_gridCell:nth-of-type(2) { order: 3; }
		.ServiceLinkList_gridCell:nth-of-type(3) { order: 2; }
		.ServiceLinkList_btnContainer { order: 4; }
	}
}