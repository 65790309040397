@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.behavior.scss";

.ServiceLinkCard {
	width: 100%;
	
	@include mq("md") {
		padding-right: var(--spacing--3xl);
	}
}

.ServiceLinkCard_link {
	width: 100%;
	display: inline-block;
}

.ServiceLinkCard_kicker {
	margin-bottom: 0.8rem;
}

.ServiceLinkCard_heading {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	column-gap: 1rem;
	@include custom-underline(activate);
}

.ServiceLinkCard_linkIcon {
	margin-top: -0.8rem;
	flex-shrink: 0;
}

.ServiceLinkCard___withText {
	.ServiceLinkCard_heading {
		@include body--lg-med;
		margin-bottom: 1.6rem;
	}
	.ServiceLinkCard_linkIcon {
		margin-top: -0.5rem;
	}
}

.ServiceLinkCard___withoutLink {
	.ServiceLinkCard_heading {
		@include custom-underline(disable);
	}
}

.ServiceLinkCard___withKicker {
	.ServiceLinkCard_heading {
		@include heading--2xl;
	}
}

// Theme: Kogp
[data-theme='kogp'] {
	.ServiceLinkCard_kicker span {
		font-weight: var(--font-weight--extrablack);
	}
	.ServiceLinkCard_linkIcon {
		color: var(--color-red-light);
	}
}
